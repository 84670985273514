import React from "react"
import { Box } from "@chakra-ui/react"
import day1 from "../../images/day1.webm"
import day1mp4 from "../../images/day1.mp4"
import firstSliderStyle from "./firstSlider.module.css"

const FirstSlider = () => {
  return (
    <Box w="100%" bgColor="#000" position="relative">
      <Box as="video" w="100%" loop muted autoPlay>
        <Box as="source" src={day1} type="video/webm" />
        <Box as="source" src={day1mp4} type="video/mp4" />
      </Box>

      <Box
        className={firstSliderStyle.container}
        position="absolute"
        color="#fff"
        zIndex="100"
        fontFamily="MuktaMahee-Regular"
     
      >
        <Box className={firstSliderStyle.title1}   fontWeight="800" fontFamily="MuktaMahee-ExtraBold">
          2.11 14:00 - 2.12 14:00 UTC
        </Box>
        <Box className={firstSliderStyle.title2}>Theme:</Box>
        <Box className={firstSliderStyle.event}>Beast Nian Nightmare</Box>
        <Box className={firstSliderStyle.title2}>What to do:</Box>
        <Box className={firstSliderStyle.event}>Collect and set off firecrackers to beat off Beast Nian</Box>
        <Box className={firstSliderStyle.title2}>You will get:</Box>
        <Box className={firstSliderStyle.event}>Limited Wearable NFT </Box>
      </Box>
    </Box>
  )
}

export default FirstSlider
