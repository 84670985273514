import React from "react"
import { Box, Text } from "@chakra-ui/react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import ActivityImg from "../../images/img.inline.svg"

const Info = () => {
  const data = useStaticQuery(graphql`
    {
      img1: file(relativePath: { eq: "img2.inline.svg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img2: file(relativePath: { eq: "img.inline.svg" }) {
        publicURL
      }
    }
  `)

  return (
    <Box w="100%" position="relative">
      <Box as="img"  w="100%" borderColor="#000" src={data.img2.publicURL} />
   
    </Box>
  )
}

export default Info
