import React from "react"
import { Box } from "@chakra-ui/react"
import day3 from "../../images/day3.webm"
import day3mp4 from "../../images/day3.mp4"
import thirdSliderStyle from "./thirdSlider.module.css"

const ThirdSlider = () => {
  return (
    <Box w="100%" bgColor="#000" position="relative">
      <Box as="video" w="100%" loop muted autoPlay >
        <Box as="source" src={day3} type="video/webm" />
        <Box as="source" src={day3mp4} type="video/mp4" />
      </Box>

      <Box
        className={thirdSliderStyle.container}
        position="absolute"
        color="#fff"
        zIndex="100"
        fontFamily="MuktaMahee-Regular"
      >
        <Box
          className={thirdSliderStyle.title1}
          fontWeight="800"
          fontFamily="MuktaMahee-ExtraBold"
        >
          2/13 14:00 - 2/14 12:00 UTC
          <Box as="pre" fontSize="2rem" fontFamily="MuktaMahee" fontWeight="500">& New Year DJing 12:00 - 13:30</Box>
        </Box>
        <Box className={thirdSliderStyle.title2}>Theme:</Box>
        <Box className={thirdSliderStyle.event}>Lion Dance Summon</Box>
        <Box className={thirdSliderStyle.title2}>What to do:</Box>
        <Box className={thirdSliderStyle.event}>
          Change Lion Dance customes for Meihuazhuang
          <pre />
          challenge, collect Ethereum shards & envelopes
        </Box>
        <Box className={thirdSliderStyle.title2}>You will get:</Box>
        <Box className={thirdSliderStyle.event}>
          Limited Wearable NFT 
        </Box>
      </Box>
    </Box>
  )
}

export default ThirdSlider
