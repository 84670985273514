import React from "react"
import { Box, Flex, Link } from "@chakra-ui/react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

const ActivityList = () => {
  const data = useStaticQuery(graphql`
    {
      img1: file(relativePath: { eq: "5.png" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
    }
  `)

  return (
    <Flex
      flexDir="column"
      w="100%"
      // minH="80vh"
      bgRepeat="no-repeat"
      bgSize="100%"
      // bgImage={"url(" + data.img1.childImageSharp.fluid.src + ")"}
    >
      <Box
        // mb="1rem"
        color="#fff"
        fontSize="7rem"
        textAlign="center"
        fontFamily="TitanOne-Regular"
        // textTransform="uppercase"
      >
        Activity Manual
      </Box>

      <Box
        bgRepeat="no-repeat"
        bgSize="100%"
        // bgImage={"url(" + data.img1.childImageSharp.fluid.src + ")"}
        // pt="4rem"
        px={["15rem", "15rem", "15rem", "25rem"]}
        flex="1"
        color="#fff"
      >
        <Box
          mt="1rem"
          textAlign="center"
          // fontWeight="800"
          fontSize="2rem"
        >
          <Link
            color="#fff"
            _hover={{ textDecor: "underline" }}
            href="https://play.decentraland.org/?position=100%2C-76&realm=fenrir-amber"
            isExternal
            _hover={{ color: "rgb(0, 74, 173)" }}
          >
            📍Location in Decentraland （100,-76）
          </Link>
        </Box>
        <Box
          mt="1rem"
          textAlign="center"
          fontWeight="800"
          color="red"
          fontSize="2rem"
        >
          ⏰ Time Schedule (UTC)
        </Box>
        <Box fontSize="1.4rem" textAlign="center">
          <Box as="span" fontWeight="800">
            Day 1
          </Box>
          <pre />
          2/11 14:00 - 2/12 14:00
        </Box>
        <Box fontSize="1.4rem" textAlign="center" mt="1rem">
          <Box as="span" fontWeight="800">
            Day 2
          </Box>
          <pre />
          2/12 14:00 - 2/13 14:00
        </Box>
        <Box fontSize="1.4rem" textAlign="center">
          <Box as="span" fontWeight="800" color="#ffc900">
            Timed quiz challenge
          </Box>
          <pre />
          2/12 14:00 - 15:30
        </Box>
        <Box fontSize="1.4rem" textAlign="center" mt="1rem" mb="1rem">
          <Box as="span" fontWeight="800">
            Day 3
          </Box>
          <pre />
          2/13 14:00 - 2/14 12:00
          <pre />
          New Year Djing
          <pre />
          2.14 12:00 - 13:30
        </Box>
      </Box>
    </Flex>
  )
}

export default ActivityList
