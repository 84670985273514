import React from "react"
import { Box, Flex, Tooltip } from "@chakra-ui/react"
import useSWR from "swr"

const fetcher = url => fetch(url).then(res => res.json())

let objDeepCopy = source => {
  var sourceCopy = source instanceof Array ? [] : {}
  for (var item in source) {
    sourceCopy[item] =
      typeof source[item] === "object"
        ? objDeepCopy(source[item])
        : source[item]
  }
  return sourceCopy
}

const Rank = () => {
  const { data, error } = useSWR(
    "https://us-central1-dragon-city-decentraland.cloudfunctions.net/app/get-ecn",
    fetcher
  )

  if (error) return "An error has occurred."
  if (!data) return "Loading..."

  let result = objDeepCopy(data)

  result.map(val => {
    val.time = new Date(val.time)
  })

  result.sort(function (a, b) {
    var value1 = a.correct,
      value2 = b.correct
    if (value1 === value2) {
      return a.time - b.time
    }
    return value2 - value1
  })

  let final = result.slice(0, 10)

  return (
    <Box pb="5rem">
      <Box
        mb="1rem"
        color="#fff"
        fontSize="7rem"
        textAlign="center"
        fontFamily="TitanOne-Regular"
      >
        Ranking
      </Box>

      <Box mb="2rem" color="#fff" color="red" fontStyle="italic" textAlign="center">
        {" "}
        Congrats! Top three winners could claim your rewards after 2/14 9:00 UTC.
      </Box>

      <Box >
        <Flex
          w="60%"
          h="3rem"
          margin="0 auto"
          bgColor="#1d1f30"
          color="#fff"
          alignItems="center"
          cursor="pointer"
          borderBottom="0.5px solid #000"
        >
          <Box flex="0.15" px="1rem" fontSize="2rem"></Box>

          <Box flex="0.22">name</Box>
          <Box flex="0.11">score</Box>
          <Box
            pl="1rem"
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            flex="0.2"
            textAlign="center"
          >
            time
          </Box>
          <Box
            pl="1rem"
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            flex="1"
            textAlign="center"
          >
            id
          </Box>
        </Flex>
        {final.map((val, index) => {
          return (
            <Flex
              key={index}
              w="60%"
              h="3rem"
              margin="0 auto"
              bgColor="#1d1f30"
              color="#fff"
              alignItems="center"
              _hover={{ border: "1px solid #dfc795" }}
              cursor="pointer"
              borderBottom="0.5px solid #000"
            >
              {/* {console.log(val)} */}
              <Box flex="0.15" px="1rem" fontSize="2rem">
                {index !== 0 ? (
                  index !== 1 ? (
                    index !== 2 ? (
                      <Box pl="0.5rem" mr="0.5rem" fontSize="1.5rem">
                        {" "}
                        {index + 1}{" "}
                      </Box>
                    ) : (
                      <>🥉</>
                    )
                  ) : (
                    <> 🥈</>
                  )
                ) : (
                  <>🥇 </>
                )}
              </Box>

              <Box flex="0.25">
                {" "}
                <Tooltip hasArrow label={val.name}>
                  {val.name.slice(0, 6) + "..."}
                </Tooltip>
              </Box>
              <Box flex="0.10">{val.correct}</Box>
              <Box
                pl="1rem"
                // overflow="hidden"
                whiteSpace="nowrap"
                // textOverflow="ellipsis"
                flex="0.2"
                textAlign="center"
              >
                <Tooltip
                  hasArrow
                  label={
                    val.time.getHours() +
                    ":" +
                    val.time.getMinutes() +
                    ":" +
                    val.time.getSeconds()
                  }
                >
                  {val.time.getHours() +
                    ":" +
                    val.time.getMinutes() +
                    ":" +
                    val.time.getSeconds()}
                </Tooltip>
       
              </Box>
              <Box >&nbsp;&nbsp;&nbsp;</Box>
              <Box
                flex="1"
                textAlign="center"
                overflow="hidden"
                whiteSpace="nowrap"
                textOverflow="ellipsis"
              >
                {val.id}
              </Box>
            </Flex>
          )
        })}
      </Box>
    </Box>
  )
}

export default Rank
