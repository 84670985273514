import React from "react"
import { Box, Link } from "@chakra-ui/react"
import day2 from "../../images/day2.webm"
import day2mp4 from "../../images/day2.mp4"
import secondSliderStyle from "./secondSlider.module.css"

const SecondSlider = () => {
  return (
    <Box w="100%" bgColor="#000" position="relative">
      <Box as="video" w="100%" loop muted autoPlay>
        <Box as="source" src={day2} type="video/webm" />
        <Box as="source" src={day2mp4} type="video/mp4" />
      </Box>

      <Box
        className={secondSliderStyle.container}
        position="absolute"
        color="#fff"
        zIndex="100"
        fontFamily="MuktaMahee-Regular"
      >
        <Box
          className={secondSliderStyle.title1}
          fontWeight="800"
          fontFamily="MuktaMahee-ExtraBold"
        >
          2.12 14:00 - 2.13 14:00 UTC
          <pre className={secondSliderStyle.time} style={{ color: "#004aad" }}>
            Timed Challenge: 14:00 - 15:30{" "}
          </pre>
        </Box>
        <Box className={secondSliderStyle.title2}>Theme:</Box>
        <Box className={secondSliderStyle.event}>
          Visitors on the Dragon Boat{" "}
        </Box>
        <Box className={secondSliderStyle.title2}>What to do:</Box>
        <Box className={secondSliderStyle.event}>
          Deliver couplets and finish 15 quizzes to collect
          <pre />
          Chinese New Year Delicacies
        </Box>
        <Box className={secondSliderStyle.title2}>You will get:</Box>
        <Box className={secondSliderStyle.event}>
          1. Limited Wearable NFT
          <pre />
          <Link
            _focus={{ boxShadow: "none" }}
            href="https://ethgifts.com/"
            isExternal
            textDecoration="underline"
            _hover={{ color: "rgb(0, 74, 173)" }}
          >
            2. Intangible Cultural Heritage NFT & physical
            <pre />
            equivalent (14:00 - 15:30 only)
            <pre />
          </Link>
          3. AI Crypto Artworks (14:00 - 15:30 only)
        </Box>
      </Box>
    </Box>
  )
}

export default SecondSlider
