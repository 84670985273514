import React from "react"
import SEO from "../components/seo"
import Info from "../components/index/info"
import FirstSlider from "../components/index/firstSlider"
import SecondSlider from "../components/index/secondSlider"
import ThirdSlider from "../components/index/thirdSlider"
import Rank from "../components/index/rank"
import "./index.css"
import { Box } from "@chakra-ui/react"
import { Web3Provider } from "@ethersproject/providers"
import { Web3ReactProvider } from '@web3-react/core'
import ActivityList from '../components/index/activityList'
import ActivityList2 from '../components/index/activityList2'


import Claim from '../components/index/claim'
const IndexPage = () => { 


  function getLibrary(provider) {
    const library = new Web3Provider(provider)
    library.pollingInterval = 12000
    return library
  }
  
  
  return(
  <Box h="100%" minW="1024px" bgColor="#000">
     <Web3ReactProvider getLibrary={getLibrary}>
    <SEO title="The Year of 0x" />
    <Info />
    <ActivityList/>
    <ActivityList2/>
    <FirstSlider />

    <SecondSlider />
    <ThirdSlider />
    <Rank />

    {/* <Claim/> */}
    <Box textAlign="center" color="#fff" pb="2rem">&nbsp;Buidl&nbsp;with&nbsp;❤️&nbsp;by&nbsp;ECN&nbsp;</Box>
    </Web3ReactProvider>
  </Box>
)}

export default IndexPage
