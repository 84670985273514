import React from "react"
import { Box, Flex, Link } from "@chakra-ui/react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import activity from "./activity.module.css"

const ActivityList = () => {
  const data = useStaticQuery(graphql`
    {
      img1: file(relativePath: { eq: "6.png" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
    }
  `)

  return (
    <Flex
      mt="2rem"
      flexDir="column"
      w="100%"
      mb="5rem"
      // minH="65vh"
      bgRepeat="no-repeat"
      bgSize="100%"
      // bgImage={"url(" + data.img1.childImageSharp.fluid.src + ")"}
    >
      <Box
        px={["15rem", "15rem", "15rem", "4rem"]}
        flex="1"
        color="#fff"
        bgRepeat="no-repeat"
        bgSize="100%"
        // bgImage={"url(" + data.img1.childImageSharp.fluid.src + ")"}
      >
        <Box
          mt="1rem"
          textAlign="center"
          fontWeight="800"
          color="red"
          fontSize="2rem"
        >
          🎁
        </Box>
        <Box fontSize="1.2rem">
          <Box
            fontSize="1.4rem"
            as="div"
            textAlign="center"
            fontWeight="800"
            color="#ffc900"
            mt="1rem"
          >
            Regular Rewards
          </Box>
          <Box textAlign="center" mt="1rem" fontSize="1.2rem">
            <pre />
            Whoever participates in any activity will receive a wearable NFT of
            the day (1000 pcs/day).
            <pre />
            To gather a whole "Lion Dance" suit after the four-day event.
            <pre />
          </Box>
        </Box>

        <Box
          fontSize="1.4rem"
          as="div"
          mt="1rem"
          textAlign="center"
          fontWeight="800"
          color="#ffc900"
        >
          Limited Crypto Artwork
        </Box>
        <Box
          fontSize="1.2rem"
          as="div"
          mt="1rem"
          textAlign="center"
          fontWeight="800"
        >
          NFT+physical equivalent; AI-Human Artwork
        </Box>
        <Box
          textAlign="center"
          className={activity.article}
          fontSize="1.2rem"
          mt="1rem"
        >
          <pre />
          ECN is privileged to have crypto artist &nbsp;
          <Link
            color="#fff"
            href="https://twitter.com/Song11Ting"
            isExternal
            textDecoration="underline"
            _hover={{ color: "rgb(0, 74, 173)" }}
          >
            Ting Song
          </Link>
          &nbsp; with us to bring a &nbsp;
          <Link
            color="#fff"
            href="https://ethgifts.com/"
            isExternal
            _hover={{ color: "rgb(0, 74, 173)" }}
            textDecoration="underline"
          >
            Lunar New Year NFT collection
          </Link>{" "}
          for Ethereum community.
        </Box>

        <Box
          textAlign="center"
          mt="1rem"
          className={activity.article}
          fontSize="1.2rem"
        >
          The special rewards will be given to top 3 winners in the timed quiz
          challenge
          {/* <pre/> (UTC 2/12 14:00 - 15:30 ONLY). */}
        </Box>

        <Box
          textAlign="center"
          // mt="1rem"
          className={activity.article}
          fontSize="1.2rem"
        >
          (UTC 2/12 14:00 - 15:30 ONLY)
        </Box>

        <Box
          textAlign="center"
          mt="1rem"
          className={activity.article}
          fontSize="1.2rem"
        >
          Ranking will be updated below for participants to check their NFT
          claim eligibility.
        </Box>
        <Box fontSize="1.2rem">
          <Box
            mb="1rem"
            fontSize="1.2rem"
            as="div"
            mt="1rem"
            textAlign="center"
            fontWeight="800"
            color="red"
          >
            SPOILER: Quizzes are Ethereum-related. Good luck :)
          </Box>
        </Box>
      </Box>
    </Flex>
  )
}

export default ActivityList
